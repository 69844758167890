<template>
  <v-navigation-drawer
    id="pages-navigation-drawer"
    v-model="drawerVX"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    color="sidebarColorLight"
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <div class="d-flex justify-center align-center" style="height: 110px; width: 100%;">
      <div>
        <v-img
          v-if="!expandOnHover"
          :src="'/assets/branding/' + ((dynamicLogo === 'verify') ? 'verifyLowResDark.png' : 'poweredByDoohLight3.png')"
          :alt="dynamicLogo + '.png'"
          :height="(dynamicLogo === 'verify') ? 100 : 75"
          contain
          class="mt-n3"
        />
        <v-img
          v-else
          :src="(dynamicLogo === 'verify') ? 'verifyChopped.png' : 'd-clamp-on-white.png'"
          :alt="dynamicLogo + '.png'"
          max-width="40"
        />
      </div>
    </div>

    <v-divider class="mb-1" />

    <v-list
      expand
      nav
    >
      <template>
        <!-- TODO: Change to $store.state.Permissions.proofOfPlay once go live-->
        <theme-item
          key="0"
          :item="items[0]"
        />
      </template>
    </v-list>
    
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-img
          v-bind="attrs"
          v-on="on"
          v-if="!expandOnHover"
          src="/assets/branding/emailWhite.png" 
          style="
            position: fixed;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%, -5%);
            margin: 0 auto;
            cursor: pointer;
          "
          @click="copyTo()"
          width="150"
        />
      </template>
      <span>Copy to clipboard</span>
  </v-tooltip>
  </v-navigation-drawer>
</template>

<script>

  // Utilities
  import { mapGetters } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dynamicLogo: 'deliver',
      items: [
        // Proof of Play
        {
          icon: 'mdi-camera',
          title: 'PoP Gallery',
          to: '/pop-gallery',
		},
      ],
    }),

    computed: {
      ...mapGetters('UI', [
        'barColor', 'barImage', 'drawer'
      ]),

      drawerVX: {
        get () {
          return this.drawer
        },
        set (val) {
          this.$store.commit('UI/SET_DRAWER', val)
        },
      },
      
      computedItems () {
        return this.items.map(this.mapItem)
      },
      
      calculateDynamicLogo() {
        if(window.location.href.includes('deliver')) {
          return 'deliver'
        }
        else if(window.location.href.includes('verify')) {
          return 'verify'
        }
        else {
          return 'deliver'
        }
      }
    },
    
    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    mounted() {
      this.dynamicLogo = this.calculateDynamicLogo
    },

    methods: {
      copyTo() {
        navigator.clipboard.writeText('pop@dooh.com')
        this.$root.$emit(
                'snackbarSuccess',
                'Email copied to clipboard.',
            )
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="scss">

  .v-list-item--active a {
    color: indianred !important;
    cursor: pointer !important;
  }

</style>
